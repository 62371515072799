






































































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Parchment from 'parchment'
import { map } from 'lodash'
import { promiseHelper } from '@/helper/promise-helper'
import { formatStringNoExtraSpace } from '@/helper/utils'
@Observer
@Component({
  components: {
    'post-image-block': () => import('./post-image-block.vue'),
    'post-video-block': () => import('./post-video-block.vue'),
    'post-link-block': () => import('./post-link-block.vue'),
    'post-location-block': () => import('./post-location-block.vue'),
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  postDialogController
  @Prop({
    default: () => {
      return {}
    },
  })
  editorHandler
  @Prop({ default: true }) enablePoll
  @Prop({ default: true }) showEmoji
  @Prop({ default: false }) isPlainText

  emojiDialog = false
  emojiPicker
  openStickerButton
  isFocusQuill = false
  lastQuillId

  toggleEmoji() {
    this.onFocusLastQuill()

    this.emojiDialog = !this.emojiDialog
  }

  onFocusLastQuill() {
    const blocks = this.editorHandler.editorBlocks
    if (!map(blocks, 'id').includes(this.lastQuillId)) {
      for (let i = 0; i < blocks.length; i++) {
        if (blocks[i].type == 'text-block') {
          this.lastQuillId = blocks[i].id
          const quillRef = this.$refs[`quill-${this.lastQuillId}`] as any
          if (quillRef) quillRef[0]?.quill?.focus()
          break
        }
      }
    } else if (!this.isFocusQuill) {
      const quillRef = this.$refs[`quill-${this.lastQuillId}`] as any
      if (quillRef) quillRef[0]?.quill?.focus()
      else {
      }
    }
  }

  mounted() {
    if (this.postDialogController.isEditPost) {
      this.editorHandler.editorBlocks.map((block) => {
        if (block.type === 'text-block') {
          const quillRef = this.$refs[`quill-${block.id}`] as any
          const quill = quillRef[0].quill
          quill.clipboard.dangerouslyPasteHTML(0, block.htmlContent)
          if (this.lastQuillId == undefined) this.lastQuillId = block.id
        }
      })
      const blockLength = this.editorHandler.editorBlocks.length
      if (
        !blockLength ||
        (this.editorHandler.editorBlocks[blockLength - 1].type !== 'text-block' &&
          this.editorHandler.editorBlocks[blockLength - 1].type !== 'location-block')
      ) {
        this.editorHandler.addTextBlock(blockLength)
      }
    }
    if (!this.showEmoji) return
    this.openStickerButton = document.getElementById('open-sticker-button')
    this.emojiPicker = document.getElementById('emoji-picker')
    document.addEventListener('click', this.clickOutEmojPickerListenter)
  }

  onSelectEmoji(emoji) {
    if (this.lastQuillId == undefined) return
    const quillRef = this.$refs[`quill-${this.lastQuillId}`] as any
    const quill = quillRef[0].quill
    var selection = quill.getSelection(true)
    quill.insertText(selection.index, emoji.data)
  }
  clickOutEmojPickerListenter(event: any) {
    const isClickInsideEmojiPicker = this.emojiPicker?.contains(event.target)
    const isClickInsideStickerButton = this.openStickerButton?.contains(event.target)
    if (!isClickInsideEmojiPicker && !isClickInsideStickerButton) {
      this.emojiDialog = false
    }
  }
  beforeDestroy() {
    if (this.showEmoji) document.removeEventListener('click', this.clickOutEmojPickerListenter)
  }

  updateTextBlocks() {
    const textBlocks = this.editorHandler.editorBlocks?.filter((item) => item.type === 'text-block')
    textBlocks?.map((item) => {
      const quillRef = this.$refs[`quill-${item.id}`] as any
      const quill = quillRef[0].quill
      const htmlContent = quill.root.innerHTML
      const rawContent = quill.getText(0)
      this.editorHandler.updateTextBlock(item.id, htmlContent, rawContent)
    })
  }
  async addMediaBlock(id, blockType: 'image' | 'video' | 'link') {
    this.onFocusLastQuill()
    if (id == undefined) {
      id = this.lastQuillId
    }
    const index = this.editorHandler.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    this.handleShowToolbar(id, false)
    // add media block and split the original text block into 2 new blocks
    const originalQuillRef = this.$refs[`quill-${id}`] as any
    const originalQuill = originalQuillRef[0]?.quill
    const lastCursor = originalQuill.selection.lastRange.index

    const frontSectionText = originalQuill.getContents(0, lastCursor || 1)
    const behindSectionText = originalQuill.getContents(lastCursor + 1)
    this.editorHandler.addEditorBlocks(index, blockType)
    if (this.editorHandler.editorBlocks[index].type === 'text-block') {
      if (lastCursor == 0) {
        this.editorHandler.removeEditorBlocks(index, 1)
      } else originalQuill.setContents(frontSectionText)
    }
    await promiseHelper.delay(100)
    const appendedQuillRef = this.$refs[`quill-${this.editorHandler.editorBlockCount - 1}`] as any
    if (!appendedQuillRef) return
    appendedQuillRef[0]?.quill?.setContents(behindSectionText)
  }

  handleShowToolbar(id, show = true) {
    const toolbar = document.getElementById(`toolbar-${id}`)
    toolbar?.setAttribute('style', `display: ${show ? 'normal' : 'none'} !important`)
  }

  setToolbarPosition(id, quillElement) {
    const selection = document.getSelection() as any
    if (selection.rangeCount < 1) return
    const node = selection.getRangeAt(0).startContainer
    const blot = Parchment.find(node)
    let block = blot as any
    while (block?.statics?.blotName !== 'block' && block?.parent) block = block?.parent
    if (!block) return
    const lineText = block.domNode.innerText
    const toolbar = document.getElementById(`toolbar-${id}`)
    if (lineText === '\n') {
      const lastRange = quillElement.selection.lastRange
      if (!lastRange) return
      const lastCursor = lastRange.index
      const bound = quillElement.getBounds(lastCursor)
      const top = bound?.top - 4
      toolbar?.setAttribute(
        'style',
        `position: absolute; top: ${top}px; display: normal !important; right: ${
          this.$vuetify.breakpoint.width <= 737 ? 0 : 12
        }px;`
      )
    } else {
      toolbar?.setAttribute('style', 'display: none !important')
    }
  }

  onFocusChange(id, event) {
    this.setToolbarPosition(id, event)
    this.lastQuillId = id
    this.isFocusQuill = true
  }
  onBlurChange(id) {
    this.handleShowToolbar(id, false)
    this.isFocusQuill = false
  }
  onContentChange(id, event) {
    this.postDialogController.changeTextBlock(id, event.quill.getText())
    this.setToolbarPosition(id, event.quill)
    setTimeout(() => {
      this.setToolbarPosition(id, event.quill)
    }, 200)
    this.lastQuillId = id
  }
}
